import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup'

class Chooser extends React.Component {

  render() {
    const entries = this.props.entries;
    const liItems = entries != null ? entries.map(element => {
      return <ListGroup.Item 
                key={this.props.idFunc(element)}
                action 
                onClick={() => this.props.onClick(element)}>
                {this.props.displayFunc(element)}
             </ListGroup.Item>}):"";

    return (
      <ListGroup>
        {liItems}
      </ListGroup>
    );
  }
}

export default Chooser;