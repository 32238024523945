import React from 'react';
import './App.css';
import Chooser from './Chooser.js';
import Music from './Music.js';
import Title from './Title.js';
import Footer from './Footer.js';
import { JSON_MIME_TYPE, FILE_LOCATION } from './Constants.js';


import Container from 'react-bootstrap/Container';

import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck'
import Alert from 'react-bootstrap/Alert';

const PLAYTOGETHER_FOLDER_NAME = 'playtogether-data';
const GDRIVE_FOLDER_MIME_TYPE = 'application/vnd.google-apps.folder';
const GDRIVE_UPLOAD_METADATA = 'https://www.googleapis.com/drive/v3/files';
const GDRIVE_LIST_PLAYTOGETHER_FOLDER = "https://www.googleapis.com/drive/v3/files?q=" + encodeURI("mimeType='" + GDRIVE_FOLDER_MIME_TYPE + "' and name = '" + PLAYTOGETHER_FOLDER_NAME + "'");
const GDRIVE_LIST_JSONS = "https://www.googleapis.com/drive/v3/files?&fields=files(name,modifiedTime,id,starred)&q=" + encodeURI("mimeType='" + JSON_MIME_TYPE + "'");

const EMPTY_PROJECT_URL = "empty-project.json";
const LIST_COMMAND = "list.php";
const TEMPLATES_COMMAND = "templates.php";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publicMusic: [],
      myMusic: [],
      templateMusic: [],
      musicSelected: null,
      login: null,
      alerts: {
        selectProject: true,
        login: true
      }
    };
  }

  render() {
    if (this.state.musicSelected == null) {
      return (
        <Container fluid>
          <Title
            profileObj={this.state.login != null ? this.state.login.profileObj : null}
            googleLoginCallback={(response) => this.googleLoginCallback(response)}
            googleLogoutCallback={(response) => this.googleLogoutCallback(response)}
          />

          {this.state.alerts.selectProject &&
            <Alert variant="info" dismissible onClose={() => this.dismissAlert("selectProject")}>
              Please select the project you want to work on.
          </Alert>
          }
          {(!this.state.login) && (this.state.alerts.login) &&
            <Alert variant="info" dismissible onClose={() => this.dismissAlert("login")}>
              Please log in to your Google Account
              and allow Playtogether to access your Google Drive to store and load files of your Private Projects.
            </Alert>
          }
          <CardDeck>
            <Card bg="light">
              <Card.Header>Community Projects</Card.Header>
              <Card.Body>
                <Chooser
                  entries={this.state.publicMusic}
                  displayFunc={element => `${element.name}`}
                  idFunc={element => element.id}
                  onClick={(element) => this.chooseMusicHost(element, FILE_LOCATION.HOST)} />
              </Card.Body>
            </Card>
            <Card bg="light">
              <Card.Header>Private Projects</Card.Header>
              <Card.Body>
                <Chooser
                  entries={this.state.myMusic}
                  displayFunc={element => `${element.name}`}
                  idFunc={element => element.id}
                  onClick={element => this.chooseMusicGdrive(element)} />
              </Card.Body>
            </Card>
            <Card bg="light">
              <Card.Header>New Project from Template</Card.Header>
              <Card.Body>
                <Chooser
                  entries={this.state.templateMusic}
                  displayFunc={element => `${element.name}`}
                  idFunc={element => element.name}
                  onClick={element => this.chooseMusicHost(element, FILE_LOCATION.LOCAL)} />
              </Card.Body>
            </Card>
          </CardDeck>
          <p></p>
          <Footer />
        </Container>
      );
    }
    else {
      return (
        <Music
          configuration={this.state.musicSelected}
          onBackFunc={() => this.onBack()}
          login={this.state.login}
          googleLoginCallback={(response) => this.googleLoginCallback(response)}
          googleLogoutCallback={(response) => this.googleLogoutCallback(response)}
          listChangeCallback={(location) => this.refreshList(location)} />
      );
    }
  }

  dismissAlert(index) {
    let newState = { ...this.state };
    newState.alerts[index] = false;
    this.setState(newState);
  }

  refreshList(location) {
    let newState = { ...this.state };
    switch (location) {
      case FILE_LOCATION.HOST:
        fetch(LIST_COMMAND).then(response => response.json()).then(
          json => {
            let newState = { ...this.state }
            newState.publicMusic = json.files;
            this.setState(newState);
          }).catch(err => console.log(err));        
        break;
      case FILE_LOCATION.GDRIVE:
        fetch(GDRIVE_LIST_JSONS, { headers: { 'Authorization': 'Bearer ' + newState.login.accessToken } }).then(response => response.json()).then(
          json => {
            newState.myMusic = json.files;
            this.setState(newState);
          }).catch(err => console.log(err));
        break;
      case FILE_LOCATION.LOCAL: // templates
        fetch(TEMPLATES_COMMAND).then(response => response.json()).then(
          json => {
            let newState = { ...this.state }
            newState.templateMusic = json.files;
            this.setState(newState);
          }).catch(err => console.log(err));
        break;
      default:
        // refresh all:
        this.refreshList(FILE_LOCATION.LOCAL);
        this.refreshList(FILE_LOCATION.HOST);
        if (this.state.login) {
          this.refreshList(FILE_LOCATION.GDRIVE);
        }
    }

  }

  componentDidMount() {
    this.refreshList(FILE_LOCATION.LOCAL);
    this.refreshList(FILE_LOCATION.HOST);
  }

  googleLoginCallback(response) {
    let newState = { ...this.state };
    newState.login = {
      accessToken: response.accessToken,
      profileObj: response.profileObj
    };

    fetch(GDRIVE_LIST_PLAYTOGETHER_FOLDER, {
      headers: { 'Authorization': 'Bearer ' + newState.login.accessToken }
    }).then(response => response.json()).then(
      (response) => {
        var files = response.files;
        if (files && files.length > 0) {
          let gdriveDirectoryId = files[0].id;
          newState.login.gdriveDirectoryId = gdriveDirectoryId;
          this.setState(newState, () =>
            this.refreshList(FILE_LOCATION.GDRIVE));
        }
        else {
          // create a new directory (ususally the first time someone works with playtogether)
          let fileMetadata = {
            'name': PLAYTOGETHER_FOLDER_NAME, // Filename at Google Drive
            'mimeType': GDRIVE_FOLDER_MIME_TYPE, // mimeType at Google Drive
            'parents': [], // Folder ID at Google Drive                        
          };

          fetch(GDRIVE_UPLOAD_METADATA, {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + newState.login.accessToken },
            body: new Blob([JSON.stringify(fileMetadata)], { type: JSON_MIME_TYPE })
          }).then(response => response.json()).then(
            (response) => {
              newState.login.gdriveDirectoryId = response.id;
              this.setState(newState);
            });
        }
      });
  }

  googleLogoutCallback(response) {
    let newState = { ...this.state };
    newState.login = null;
    newState.myMusic = null;
    this.setState(newState);
  }

  onBack() {
    let newState = { ...this.state };
    newState.musicSelected = null;
    this.setState(newState);
  }

  createNewProject() {
    // load the music
    fetch(EMPTY_PROJECT_URL).then(
      response => response.json()).then(
        configuration => {
          let newState = { ...this.state };
          configuration.location = FILE_LOCATION.LOCAL;
          configuration.composer = this.state.login.profileObj.name;
          newState.musicSelected = configuration;
          this.setState(newState);
        });
  }

  chooseMusicHost(element, location) {
    // load the music
    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    
    var nocache = {
      method: 'GET',
      headers: myHeaders,
    };
        
    fetch(element.url, nocache).then(
      response => response.json()).then(
        configuration => {
          let newState = { ...this.state };
          configuration.location = location;
          configuration.id = element.id;
          newState.musicSelected = configuration;
          if (location === FILE_LOCATION.LOCAL && this.state.login) {
            configuration.composer = this.state.login.profileObj.name;
          }
          this.setState(newState);
        });
  }

  chooseMusicGdrive(element) {
    fetch(GDRIVE_UPLOAD_METADATA + "/" + element.id + "?alt=media", {
      headers: { 'Authorization': 'Bearer ' + this.state.login.accessToken }
    }).then(response => response.json()).then(
      configuration => {
        let newState = { ...this.state };
        configuration.location = FILE_LOCATION.GDRIVE;
        configuration.id = element.id;

        // load all gdrive videos
        let videoPromises = [];
        configuration.voices.forEach(voice => {
          voice.videos.forEach(video => {
            if (video.location === FILE_LOCATION.GDRIVE) {
              videoPromises.push(
                fetch(GDRIVE_UPLOAD_METADATA + "/" + video.gdid + "?alt=media", {
                  headers: { 'Authorization': 'Bearer ' + this.state.login.accessToken }
                }).then(response => response.blob()).then(blob => {
                  video.localurl = window.URL.createObjectURL(blob);
                }));
            }
          });
        });
        Promise.all(videoPromises).then(() => {
          newState.musicSelected = configuration;
          this.setState(newState);
        });
      });
  }
}

export default App;
