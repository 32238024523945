import React from 'react';
import { FILE_LOCATION } from './Constants.js';

import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'


function MusicButtonToolbar(props) {
    return <ButtonToolbar className="sticky-top bg-dark py-2 rounded">
        <ButtonGroup className="mx-1">
            <Button
                id="back-button"
                disabled={props.isPlaying > 0 || props.isRecording || props.isSaving}
                onClick={() => props.onBackFunc()}>Back</Button>
        </ButtonGroup>
        <ButtonGroup className="mx-1">
            <Button
                id="start-button"
                disabled={props.isPlaying > 0 || props.isRecording || props.isSaving}
                onClick={() => props.onPlay()}>Play</Button>
            <Button
                id="stop-button"
                disabled={(props.isPlaying === 0 && !props.isRecording) || props.isSaving}
                onClick={() => props.onStop()}>Stop</Button>
        </ButtonGroup>
        {props.configLocation !== FILE_LOCATION.HOST &&
            <ButtonGroup className="ml-1">
                <Button
                    id="save-button"
                    disabled={props.isPlaying > 0 || props.isRecording || !props.isEdited || !props.isLogin || props.isSaving}
                    onClick={() => props.saveConfig()}>Save</Button>
                <Button
                    id="publish-button"
                    disabled={props.isPlaying > 0 || props.isRecording || !props.isLogin || props.configLocation !== FILE_LOCATION.GDRIVE || props.isSaving}
                    onClick={() => props.publishConfig()}>Publish</Button>
            </ButtonGroup>
        }
        {props.configLocation === FILE_LOCATION.HOST &&
            <ButtonGroup className="ml-1">
                <Button
                    id="publish-button"
                    disabled={props.isPlaying > 0 || props.isRecording || !props.isEdited || !props.isLogin || props.isSaving}
                    onClick={() => props.publishConfig()}>Publish</Button>
            </ButtonGroup>
        }
    </ButtonToolbar>;
}

export default MusicButtonToolbar;