import React from 'react';
import Card from 'react-bootstrap/Card';

function Footer(props) {
    return <Card bg="light">
        <Card.Body>
            © 2020 by Silvan Saxer - Made with ❤ in Switzerland - <a href="datenschutzerklaerung.pdf">[Datenschutzerkl&auml;rung]</a> <a href="nutzungsbedingungen.pdf">[Nutzungsbedingungen]</a>
        </Card.Body>
    </Card>;
}

export default Footer;