import React from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { ENV_CONFIG } from './Constants.js';

const CLIENT_ID = ENV_CONFIG.api.CLIENT_ID;
const SCOPES = 'https://www.googleapis.com/auth/drive.file';

function Account(props) {
    if (props.profileObj) {
        return <div className="titlebar-logout">
            <div className="media">
                <img className="align-self-center mr-3 rounded-circle login-logo media-left" src={props.profileObj.imageUrl} alt=""></img>
                <div className="media-body">
                    <h5>{props.profileObj.name}</h5>
                    {props.profileObj.email}
                </div>
                <GoogleLogout
                    clientId={CLIENT_ID}
                    render={renderProps => (
                        <button className="btn btn-light align-self-center mr-3" type="button"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}>
                            Logout</button>)}
                    onLogoutSuccess={(response) => props.googleLogoutCallback(response)}
                />
            </div>
        </div>
    }
    else {
        return <div className="titlebar-login">
            <div className="media">
                <GoogleLogin
                    clientId={CLIENT_ID}
                    render={renderProps => (
                        <button className="btn btn-light align-self-center mr-3" type="button"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}>Login</button>)}
                    onSuccess={(response) => props.googleLoginCallback(response)}
                    onFailure={(response) => props.googleLogoutCallback(response)}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                    scope={SCOPES}
                />
            </div>
        </div>
    }
}

export default Account;