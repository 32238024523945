export const VIDEO_MIME_TYPE = 'video/webm';
export const JSON_MIME_TYPE = 'application/json';

export const FILE_LOCATION = {
    LOCAL: "local",
    HOST: "host",
    GDRIVE: "gdrive",
    GDRIVE_PUBLIC: "gdrive-public",
    EMPTY: "empty",
};

export const VIDEO_RESOLUTION = { // 720p HDTV 16:9
    WIDTH: 1280,
    HEIGHT: 720,
};

const prod = {
    api: {
        CLIENT_ID: "827279252502-7kqi67d0n88h43fl47bemrtnciivdc9r.apps.googleusercontent.com"
    }
};

const dev = {
    api: {
        CLIENT_ID: '438270676423-c5doce3gc01v3bl4l61t3bsmtvb8e7cc.apps.googleusercontent.com'
    }
};

export const ENV_CONFIG = process.env.NODE_ENV === "development" ? dev : prod;