import React from 'react';
import Voice from './Voice.js';
import { FILE_LOCATION } from './Constants.js';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl';

import ProgressBar from 'react-bootstrap/ProgressBar'

function MusicWorkArea(props) {
    const voiceElements = props.voices.map((voice) => {
        return <Voice
            key={voice.id}
            voice={voice}
            disabled={props.isPlaying > 0 || props.isRecording || props.isSaving}
            isRecording={props.isRecording === voice.id}
            isSaving={props.isSaving}
            onSelectFunc={props.onSelectFunc}
            onRecordFunc={props.onRecordFunc}
            onRemoveFunc={props.onRemoveFunc}
            onMuteFunc={props.onMuteFunc}
            onChangeFunc={props.onChangeFunc}
            onEndedFunc={props.onEndedFunc} />
    });

    return <Row className="mt-2">
                    <Col lg="2">
                        <div className="camera">
                            <Card>
                                <Card.Header className="d-none d-sm-block">
                                    <video width="100%" ref={props.cameraStreamRef} autoPlay muted volume="0"></video>
                                </Card.Header>
                                <Card.Body>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Prepend><InputGroup.Text id="title">Title</InputGroup.Text></InputGroup.Prepend>
                                        <FormControl
                                            placeholder="Title"
                                            aria-label="Title"
                                            aria-describedby="title"
                                            type="text"
                                            disabled={props.isPlaying > 0 || props.isRecording || props.configLocation === FILE_LOCATION.HOST}
                                            value={props.configTitle}
                                            onChange={(e) => props.onChangeProjectPropertyFunc("title", e.target.value)} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroup.Prepend><InputGroup.Text id="composer">Composer</InputGroup.Text></InputGroup.Prepend>
                                        <FormControl
                                            placeholder="Composer"
                                            aria-label="Composer"
                                            aria-describedby="composer"
                                            type="text"
                                            disabled={props.isPlaying > 0 || props.isRecording || props.configLocation === FILE_LOCATION.HOST}
                                            value={props.configComposer}
                                            onChange={(e) => props.onChangeProjectPropertyFunc("composer", e.target.value)} />
                                    </InputGroup>
                                </Card.Body>
                                <Card.Footer className="d-none d-sm-block">
                                    {props.configLocation}
                                    {props.isSaving &&
                                        <ProgressBar animated now={99} />}
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <Container fluid>
                            <Row xs="1" sm="1" md="2" lg="2" xl="3">
                                {voiceElements}
                                <Col className="mb-4">
                                    <Card>
                                        <Card.Header onClick={() => props.addNewVoiceFunc()}>+</Card.Header>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>;
}

export default MusicWorkArea;