import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FILE_LOCATION } from './Constants.js';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl';

const MILLISECOND_FACTOR = 1000;

function VideoButtons(props) {
    return (
        <Col xs="4">
            <ButtonGroup vertical>
                <Button
                    disabled={props.disabled}
                    onClick={() => props.onRecordFunc(props.voiceId)}>
                    Record</Button>
                    <Button
                        disabled={props.removeDisabled}
                        onClick={() => props.onRemoveFunc(props.voiceId)}>
                        Remove</Button>
                {(!props.muted) &&
                    <Button
                        disabled={props.disabled}
                        onClick={() => props.onMuteFunc(props.voiceId)}>
                        Mute</Button>}
                {(props.muted) &&
                    <Button
                        disabled={props.disabled}
                        onClick={() => props.onMuteFunc(props.voiceId)}>
                        Un-mute</Button>}
            </ButtonGroup>
        </Col>);
}

function VideoCarousel(props) {
    if (props.isRecording) {
        return <Col xs="8">
            Recording...
                </Col>
    }
    else if (props.isSaving) {
        return <Col xs="8">
            Saving...
                </Col>
    }
    else {
        const carouselItems = props.videos.map((video) => {
            if (!video.ref){video.ref = React.createRef();}
            return <Carousel.Item key={video.id}>
                {(video.location !== FILE_LOCATION.EMPTY &&
                    <video style={{ "width": "100%" }}
                        src={video.localurl ? video.localurl : video.url}
                        id={video.id}
                        ref={video.ref}
                        onEnded={() => props.onEndedFunc(video.id)}></video>)}
                {(video.location === FILE_LOCATION.EMPTY &&
                    <div>&nbsp;</div>)}
            </Carousel.Item>
        });

        return (
            <Col xs="8">
                <Carousel
                    activeIndex={props.selectedVideoIndex}
                    indicators={false}
                    interval={null}
                    wrap={false}
                    onSelect={(selectedIndex, e) => props.onSelectFunc(props.voiceId, selectedIndex)}>
                    {carouselItems}
                </Carousel>
            </Col>
        );
    }
}

function Videos(props) {
    return (
        <Card.Header>
            <Container fluid>
                <Row>
                    <VideoCarousel
                        voiceId={props.voiceId}
                        videos={props.videos}
                        isRecording={props.isRecording}
                        isSaving={props.isSaving}
                        selectedVideoIndex={props.selectedVideoIndex}
                        onSelectFunc={props.onSelectFunc}
                        onEndedFunc={props.onEndedFunc}
                    />
                    <VideoButtons
                        disabled={props.disabled}
                        removeDisabled={props.removeDisabled}
                        voiceId={props.voiceId}
                        muted={props.muted}
                        isPublicVoice={props.isPublicVoice}
                        onRecordFunc={props.onRecordFunc}
                        onRemoveFunc={props.onRemoveFunc}
                        onMuteFunc={props.onMuteFunc}
                    />
                </Row>
            </Container>
        </Card.Header>
    );
}

function Metadata(props) {
    return (<Card.Body>
        <InputGroup className="mb-2">
            <InputGroup.Prepend><InputGroup.Text id="voicename">Voice Name</InputGroup.Text></InputGroup.Prepend>
            <FormControl
                placeholder="voice name"
                aria-label="Voice Name"
                aria-describedby="voicename"
                type="text"
                disabled={props.disabled || props.isPublicVoice}
                value={props.name}
                onChange={(e) => props.onChangeFunc(props.voiceId, "name", e.target.value)} />
        </InputGroup>
        <InputGroup className="mb-2">
            <InputGroup.Prepend><InputGroup.Text id="instrument">Instrument</InputGroup.Text></InputGroup.Prepend>
            <FormControl
                placeholder="voice instrument"
                aria-label="Instrument"
                aria-describedby="instrument"
                type="text"
                disabled={props.disabled || props.isPublicVoice}
                value={props.instrument}
                onChange={(e) => props.onChangeFunc(props.voiceId, "instrument", e.target.value)} />
        </InputGroup>
        {props.hasVideo && <InputGroup>
            <InputGroup.Prepend><InputGroup.Text id="delay">Delay</InputGroup.Text></InputGroup.Prepend>
            <FormControl
                placeholder="delay in milliseconds"
                aria-label="Delay"
                aria-describedby="delay"
                type="number"
                min="0"
                disabled={props.disabled}
                value={props.videodelay * MILLISECOND_FACTOR}
                onChange={(e) => props.onChangeFunc(props.voiceId, "videos." + props.selectedVideoIndex + ".delay", e.target.value / MILLISECOND_FACTOR)} />
        </InputGroup>}
    </Card.Body>);
}

class Voice extends React.Component {
    render() {
        let voice = this.props.voice;
        let hasVideo = voice.videos.length > 0;
        let selectedVideoIndex = hasVideo ? voice.selectedVideo - 1 : 0;
        let selectedVideo = hasVideo ? voice.videos[selectedVideoIndex] : null;
        let isPublicVoice = voice.videos.reduce(
            ((accumulator, currentVideo) => accumulator || currentVideo.location === FILE_LOCATION.GDRIVE_PUBLIC),
            false
        );

        return (
            <Col className="mb-4">
                <Card>
                    <Videos
                        disabled={this.props.disabled}
                        removeDisabled={selectedVideo === null || !(selectedVideo.location === FILE_LOCATION.LOCAL || selectedVideo.location === FILE_LOCATION.GDRIVE)}
                        voiceId={voice.id}
                        videos={voice.videos}
                        selectedVideoIndex={selectedVideoIndex}
                        muted={voice.muted}
                        isRecording={this.props.isRecording}
                        isSaving={this.props.isSaving}
                        isPublicVoice={isPublicVoice}
                        onRecordFunc={this.props.onRecordFunc}
                        onRemoveFunc={this.props.onRemoveFunc}
                        onMuteFunc={this.props.onMuteFunc}
                        onEndedFunc={this.props.onEndedFunc}
                        onSelectFunc={this.props.onSelectFunc} />

                    <Metadata
                        disabled={this.props.disabled}
                        voiceId={voice.id}
                        videos={voice.videos}
                        hasVideo={hasVideo}
                        selectedVideoIndex={selectedVideoIndex}
                        name={voice.name}
                        instrument={voice.instrument}
                        videodelay={hasVideo ? selectedVideo.delay : 0}
                        isPublicVoice={isPublicVoice}
                        onChangeFunc={this.props.onChangeFunc}
                    />

                    <Card.Footer className="d-none d-sm-block">
                        {hasVideo ? selectedVideo.location : FILE_LOCATION.EMPTY}
                    </Card.Footer>
                </Card>
            </Col>
        );
    }
}

export default Voice;