import React from 'react';

import Title from './Title.js';
import MusicButtonToolbar from './MusicButtonToolbar.js';
import MusicWorkArea from './MusicWorkArea.js';
import Footer from './Footer.js';

import { VIDEO_MIME_TYPE, VIDEO_RESOLUTION, JSON_MIME_TYPE, FILE_LOCATION } from './Constants.js';

import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

const GDRIVE_UPLOAD_PARAMS = '?uploadType=multipart&fields=id,webContentLink';
const GDRIVE_UPLOAD_FILE = 'https://www.googleapis.com/upload/drive/v3/files' + GDRIVE_UPLOAD_PARAMS;


class Music extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            config: props.configuration,
            mediaRecorder: null,
            isPlaying: 0,
            isRecording: null,
            isSaving: false,
            isEdited: false,
        };

        this.voiceIdCounter = 0;
        props.configuration.voices.forEach(voice => {
            if (typeof voice.id === "number") {
                this.voiceIdCounter = Math.max(voice.id, this.voiceIdCounter);
            }
        });

        this.cameraStreamRef = React.createRef();
        this.playCanvasRef = React.createRef();
    }

    render() {
        return (
            <Container fluid className="px-0">
                <Title
                    profileObj={this.props.login != null ? this.props.login.profileObj : null}
                    googleLoginCallback={(response) => this.props.googleLoginCallback(response)}
                    googleLogoutCallback={(response) => this.props.googleLogoutCallback(response)}
                />
                <MusicButtonToolbar
                    isPlaying={this.state.isPlaying}
                    isRecording={this.state.isRecording}
                    isSaving={this.state.isSaving}
                    configLocation={this.state.config.location}
                    isEdited={this.state.isEdited}
                    isLogin={this.props.login}

                    onBackFunc={this.props.onBackFunc}
                    onPlay={() => this.play()}
                    onStop={() => this.stop()}
                    saveConfig={() => this.saveConfig()}
                    publishConfig={() => this.publishConfig()}
                />
                {(!this.props.login) &&
                    <Alert variant="info">
                        Please log in to your Google Account and allow Playtogether to access your Google Drive to save or publish your content.
                    </Alert>
                }
                <canvas className="sticky-top"
                    style={{ "width": "100%" }}
                    width={VIDEO_RESOLUTION.WIDTH}
                    height={VIDEO_RESOLUTION.HEIGHT}
                    ref={this.playCanvasRef}
                    hidden={(this.state.isPlaying <= 0 && this.state.isRecording === null)}
                />
                <MusicWorkArea
                    voices={this.state.config.voices}
                    isPlaying={this.state.isPlaying}
                    isRecording={this.state.isRecording}
                    isSaving={this.state.isSaving}

                    onSelectFunc={(voiceId, selectedIndex) => this.onSelectVideo(voiceId, selectedIndex)}
                    onRecordFunc={(voiceId) => this.onRecord(voiceId)}
                    onRemoveFunc={(voiceId) => this.onRemoveVoice(voiceId)}
                    onMuteFunc={(voiceId) => this.onMuteVoice(voiceId)}
                    onChangeFunc={(voiceId, index, newvalue) => this.onChangeProp(voiceId, index, newvalue)}
                    onEndedFunc={() => this.onEnded()}

                    cameraStreamRef={this.cameraStreamRef}
                    configLocation={this.state.config.location}
                    configTitle={this.state.config.title}
                    configComposer={this.state.config.composer}

                    onChangeProjectPropertyFunc={(property, newvalue) => this.onChangeProjectProperty(property, newvalue)}
                    addNewVoiceFunc={() => this.addNewVoice()}
                />
                <Footer />
            </Container >
        );
    }

    onSelectVideo(voiceId, selectedIndex) {
        let newState = { ...this.state };
        let newConfig = { ...newState.config };

        let index = this.findVoiceIndex(newConfig.voices, voiceId);
        newConfig.voices[index] = { ...newConfig.voices[index] };
        newConfig.voices[index].selectedVideo = selectedIndex + 1;

        this.setState(newState);
    }

    onEnded() {
        let newState = { ...this.state };
        newState.isPlaying--;
        this.setState(newState);
    }

    onChangeProjectProperty(property, newvalue) {
        let newState = { ...this.state };
        let newConfig = { ...newState.config };
        newConfig[property] = newvalue;
        newState.config = newConfig;
        newState.isEdited = true;
        this.setState(newState);
    }

    addNewVoice() {
        this.voiceIdCounter++;
        let newvoice = {
            id: this.voiceIdCounter,
            name: "new",
            instrument: "new",
            pdfurl: "",
            selectedVideo: 1,
            videos: []
        }
        let newState = { ...this.state };
        newState.config.voices.push(newvoice);
        this.setState(newState);
    }

    onRecord(voiceId) {
        let newState = { ...this.state };
        newState.isRecording = voiceId;
        this.setState(newState, () => {
            this.state.mediaRecorder.start();
            this.play();
        });
    }

    onMuteVoice(voiceId) {
        let newState = { ...this.state };

        let voiceIndex = this.findVoiceIndex(newState.config.voices, voiceId);
        let voice = newState.config.voices[voiceIndex];
        let newvoice = { ...voice };
        newvoice.muted = !voice.muted;
        newState.config.voices[voiceIndex] = newvoice;

        this.setState(newState);
    }

    onRemoveVoice(voiceId) {
        let newState = { ...this.state };

        let voiceIndex = this.findVoiceIndex(newState.config.voices, voiceId);
        let selectedVoice = this.state.config.voices[voiceIndex];

        if (selectedVoice.videos[selectedVoice.selectedVideo - 1].location === FILE_LOCATION.LOCAL
            || selectedVoice.videos[selectedVoice.selectedVideo - 1].location === FILE_LOCATION.GDRIVE) {

            let newVoices = [...newState.config.voices];
            if (selectedVoice.videos.length > 1) {
                // remove the currently selected video            

                let newVideos = [...selectedVoice.videos];
                newVideos.splice(selectedVoice.selectedVideo - 1, 1);
                newVoices[voiceIndex].videos = newVideos;
                newVoices[voiceIndex].selectedVideo = Math.max(1, newVoices[voiceIndex].selectedVideo - 1);
            }
            else {
                // remove the voice
                newVoices.splice(voiceIndex, 1);
            }

            newState.config.voices = newVoices;
            newState.isEdited = true;

            this.setState(newState);
        }
    }

    setRecording(data) {
        if (data.size > 0) {
            // create video url from recorded data
            const recordedChunks = [];
            recordedChunks.push(data);
            var buffer = new Blob(recordedChunks);
            const url = window.URL.createObjectURL(buffer);

            let video = {
                id: url,
                url: url,
                location: FILE_LOCATION.LOCAL,
                localurl: url,
                delay: 0
            }

            // update state (set url and stop recording)
            const i = this.findVoiceIndex(this.state.config.voices, this.state.isRecording);
            const newState = { ...this.state }
            newState.config.voices[i] = { ...this.state.config.voices[i] };
            newState.config.voices[i].selectedVideo = newState.config.voices[i].videos.push(video);
            newState.isRecording = null;
            newState.isEdited = true;
            this.setState(newState);
        }
        else {
            console.log("nothing recorded...");
        }
    }

    onChangeProp(voiceId, index, newvalue) {
        const i = this.findVoiceIndex(this.state.config.voices, voiceId);
        const newState = { ...this.state }
        newState.config.voices[i] = { ...this.state.config.voices[i] };

        let selectors = index.split(".");
        let object = newState.config.voices[i];

        for (let index = 0; index < selectors.length - 1; index++) {
            object = object[selectors[index]];
        }
        object[selectors[selectors.length - 1]] = newvalue;
        newState.isEdited = true;
        this.setState(newState);
    }

    findVoiceIndex(voices, id) {
        for (let i = 0; i < voices.length; i++) {
            if (voices[i].id === id) {
                return i;
            }
        }
        return -1;
    }

    componentDidMount() {
        // start the video stream
        if (this.state.mediaRecorder == null) {
            // setup the camera stream
            navigator.mediaDevices.getUserMedia({
                audio: { echoCancellation: true },
                video: { width: VIDEO_RESOLUTION.WIDTH, height: VIDEO_RESOLUTION.HEIGHT }
            }).then((stream) => {
                // route the camera stream to the video element                
                this.cameraStreamRef.current.srcObject = stream;

                // setup the media recorder
                let mediaRecorder = new MediaRecorder(stream, { mimeType: VIDEO_MIME_TYPE });
                mediaRecorder.addEventListener(
                    'dataavailable',
                    (e) => this.setRecording(e.data)
                );

                // update state
                let newState = { ...this.state };
                newState.mediaRecorder = mediaRecorder;
                this.setState(newState);
            });
        }
    }

    play() {
        const configuration = this.state.config;
        let playCount = 0;
        configuration.voices.forEach(voice => {
            if (voice.videos.length > 0) {
                let videoRef = voice.videos[voice.selectedVideo - 1].ref;
                let videodelay = voice.videos[voice.selectedVideo - 1].delay;
                const videoElement = videoRef.current;
                if (videoElement != null && !videoElement.error && !voice.muted) {
                    videoElement.currentTime = videodelay ? videodelay : 0;
                    videoElement.play();
                    playCount++;
                }
            }
        });
        let newState = { ...this.state };
        newState.isPlaying = playCount;
        this.setState(newState, () => this.playRefreshCallback());
    }

    playRefreshCallback() {
        if (this.state.isPlaying <= 0 && this.state.isRecording === null) {
            let canvas = this.playCanvasRef.current;
            let context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            return;
        }

        this.drawPlayback();
        let self = this;
        setTimeout(function () {
            self.playRefreshCallback();
        }, 10);
    }

    drawPlayback() {
        const configuration = this.state.config;
        let canvas = this.playCanvasRef.current;

        if (canvas !== null) {
            let context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);

            let indexX = 0;
            let indexY = 0;
            
            console.log(this.state.isPlaying);

            const nofVideos = this.state.isPlaying + ((this.state.isRecording !== null) ? 1 : 0);
            
            const columns = Math.ceil(Math.sqrt(nofVideos));
            const videoWidth = Math.floor(VIDEO_RESOLUTION.WIDTH / columns);

            if (this.state.isRecording !== null) {
                const camera = this.cameraStreamRef.current;
                let videoHeight = Math.floor((videoWidth * camera.videoHeight) / camera.videoWidth);
                context.drawImage(camera, indexX, indexY, videoWidth, videoHeight);
                context.beginPath();
                context.lineWidth = "3";
                context.strokeStyle = "red";
                context.rect(indexX, indexY, videoWidth, videoHeight);
                context.stroke();

                indexX += videoWidth;
                if (indexX >= VIDEO_RESOLUTION.WIDTH - 5) {
                    indexX = 0;
                    indexY += videoHeight;
                }
            }

            configuration.voices.forEach(voice => {
                if (voice.videos.length > 0) {
                    let videoRef = voice.videos[voice.selectedVideo - 1].ref;
                    const videoElement = videoRef.current;

                    if (videoElement != null && !videoElement.error && !voice.muted && !videoElement.ended) {
                        let videoHeight = Math.floor((videoWidth * videoElement.videoHeight) / videoElement.videoWidth);
                        context.drawImage(videoElement, indexX, indexY, videoWidth, videoHeight);
                        indexX += videoWidth;
                        if (indexX >= VIDEO_RESOLUTION.WIDTH - 5) {
                            indexX = 0;
                            indexY += videoHeight;
                        }
                    }
                }
            });
        }
    }

    stop() {
        const configuration = this.state.config;
        configuration.voices.forEach(voice => {
            voice.videos.forEach(video => {
                let videoElement = document.getElementById(video.id);
                if (videoElement != null) {
                    videoElement.pause();
                }
            });
        });

        if (this.state.mediaRecorder && this.state.mediaRecorder.state === "recording") {
            this.state.mediaRecorder.stop();
        }

        let newState = { ...this.state };
        newState.isPlaying = 0;
        this.setState(newState);
    }

    publishConfig() {
        let newState = { ...this.state };
        newState.isSaving = true;
        this.setState(newState);

        const newConfig = { ...newState.config };
        newConfig.voices = [];

        let uploadPromises = [];
        let videoPromises = [];
        this.state.config.voices.forEach(voice => {
            let newVoice = { ...voice };
            newConfig.voices.push(newVoice);
            newVoice.videos = [];
            voice.videos.forEach(video => {
                let newVideo = { ...video };
                newVoice.videos.push(newVideo);
                if (video.location === FILE_LOCATION.GDRIVE) {
                    newVideo.location = FILE_LOCATION.GDRIVE_PUBLIC;
                    newVideo.localurl = null;
                    videoPromises.push(this.makeFileReadableForAnyone(video.gdid));
                }
                if (video.location === FILE_LOCATION.LOCAL) {
                    uploadPromises.push(fetch(newVideo.localurl).then(r => r.blob()).then(file => this.uploadToGdrive(
                        voice.name + '-' + voice.instrument,
                        VIDEO_MIME_TYPE,
                        file)).then((gDriveResult) => {
                            newVideo.url = gDriveResult.url;
                            newVideo.id = gDriveResult.id;
                            newVideo.gdid = gDriveResult.id;
                            newVideo.location = FILE_LOCATION.GDRIVE_PUBLIC;
                            newVideo.localurl = null;
                            videoPromises.push(this.makeFileReadableForAnyone(newVideo.gdid));
                        }));
                }
            });
        });
        Promise.all(uploadPromises).then(() => {
            Promise.all(videoPromises).then(() => {
                if (this.state.config.location === FILE_LOCATION.GDRIVE) {
                    newConfig.id = ""; // create a new file
                    newConfig.location = "";
                }
                // otherwise update it!

                const formData = new FormData();

                formData.append('file', new Blob([JSON.stringify(newConfig)], { type: JSON_MIME_TYPE }));

                fetch('config.php', {
                    method: 'POST',
                    body: formData
                })
                    .then((response) => response.json()).then((result) => {
                        newState = { ...newState };
                        newState.isSaving = false;
                        newState.config = result;
                        newState.config.location = FILE_LOCATION.HOST;

                        result.voices.forEach(v => {
                            if (typeof v.id === "number") {
                                this.voiceIdCounter = Math.max(v.id, this.voiceIdCounter);
                            }
                        });

                        this.setState(newState, () => {
                            this.props.listChangeCallback(FILE_LOCATION.HOST);
                        });
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            });
        });
    }


    saveConfig() {
        let newState = { ...this.state };
        newState.isSaving = true;
        this.setState(newState);

        newState = { ...newState };
        newState.isSaving = false;
        const newConfig = { ...newState.config };
        newConfig.voices = [];
        newState.config = newConfig;

        let videoPromises = [];

        this.state.config.voices.forEach(voice => {
            let newVoice = { ...voice };
            newVoice.videos = [];
            voice.videos.forEach(video => {
                let newVideo = { ...video };
                switch (video.location) {
                    case FILE_LOCATION.EMPTY:
                        // remove empty videos before saving
                        break;
                    case FILE_LOCATION.LOCAL: // create
                        newVideo.localurl = video.url; // save the local url
                        videoPromises.push(fetch(newVideo.localurl).then(r => r.blob()).then(file => this.uploadToGdrive(
                            voice.name + '-' + voice.instrument,
                            VIDEO_MIME_TYPE,
                            file)).then((gDriveResult) => {
                                newVideo.url = gDriveResult.url;
                                newVideo.id = gDriveResult.id;
                                newVideo.gdid = gDriveResult.id;
                                newVideo.location = FILE_LOCATION.GDRIVE;
                            }));
                        newVoice.videos.push(newVideo);
                        break;
                    case FILE_LOCATION.GDRIVE:
                    case FILE_LOCATION.GDRIVE_PUBLIC:
                    case FILE_LOCATION.HOST:
                    default:
                        newVoice.videos.push(newVideo);
                }
            });
            if (newVoice.videos.length > 0) {
                // only add voice if it has at least one video.
                newConfig.voices.push(newVoice);
            }
        });
        Promise.all(videoPromises).then(() => {
            let promise = null;
            if (newConfig.location === FILE_LOCATION.GDRIVE) {
                promise = this.updateToGdrive(newConfig.title, JSON_MIME_TYPE, new Blob([JSON.stringify(newConfig)], { type: JSON_MIME_TYPE }), newConfig.id);
            }
            else {
                newConfig.location = FILE_LOCATION.GDRIVE;
                promise = this.uploadToGdrive(newConfig.title, JSON_MIME_TYPE, JSON.stringify(newConfig));
            }
            promise.then(gDriveResult => {
                newConfig.id = gDriveResult.id;
                newState.isEdited = false;
                this.props.listChangeCallback(FILE_LOCATION.GDRIVE);
                this.setState(newState);
            });
        });
    }

    makeFileReadableForAnyone(fileId) {
        const permissions = {
            "kind": "drive#permission",
            "type": "anyone",
            "role": "reader"
        }
        let accessToken = this.props.login.accessToken;
        var url = "https://www.googleapis.com/drive/v3/files/" + fileId + "/permissions";

        return fetch(url, {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + accessToken },
            body: new Blob([JSON.stringify(permissions)], { type: JSON_MIME_TYPE })
        }).then(response => response.json()).catch(err => console.log(err));
    }

    uploadToGdrive(name, mimeType, file) {
        var metadata = {
            'name': name, // Filename at Google Drive
            'mimeType': mimeType, // mimeType at Google Drive
            'parents': [this.props.login.gdriveDirectoryId], // Folder ID at Google Drive
        };

        var accessToken = this.props.login.accessToken;

        var form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: JSON_MIME_TYPE }));
        form.append('file', file);

        var xhr = new XMLHttpRequest();

        return new Promise(function (resolve) {
            xhr.open('post', GDRIVE_UPLOAD_FILE);
            xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
            xhr.responseType = 'json';
            xhr.onload = () => {
                resolve({
                    id: xhr.response.id,
                    url: xhr.response.webContentLink
                });
            };
            xhr.send(form);
        })
    }

    updateToGdrive(name, mimeType, file, fileId) {
        var metadata = {
            'name': name, // Filename at Google Drive
            'mimeType': mimeType, // mimeType at Google Drive
            // Note: Folder cannot be updated
        };

        var accessToken = this.props.login.accessToken;
        var form = new FormData();

        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: JSON_MIME_TYPE }));
        form.append('file', file);
        form.append('fileId', fileId);

        var xhr = new XMLHttpRequest();

        return new Promise(function (resolve) {
            xhr.open('POST', "update.php");
            xhr.setRequestHeader('authorization', 'Bearer ' + accessToken);
            xhr.responseType = 'json';
            xhr.onload = () => {
                resolve({ id: xhr.response.id }); // Retrieve uploaded file ID.
            };
            xhr.send(form);
        })
    }


}

export default Music;