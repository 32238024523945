import React from 'react';
import Account from './Account.js';

function Title(props) {
  return <div className="pb-2 mt-4 mb-2 border-bottom titlebar">
    <div className="titlebar-text">
      <h1>Playtogether <small className="subtitle">Make&nbsp;music&nbsp;together@home</small></h1>
    </div>
    <Account
      profileObj={props.profileObj}
      googleLoginCallback={(response) => props.googleLoginCallback(response)}
      googleLogoutCallback={(response) => props.googleLogoutCallback(response)}
    />
  </div>;
}

export default Title;